import {createTranslator, dictionaryLanguageSchema, getDictionary} from '@questovery/dictionary';
import {ActionFunctionArgs, LoaderFunctionArgs, json, redirect} from '@remix-run/node';
import {Outlet, useLoaderData} from '@remix-run/react';
import {useMatchedData} from '~/core/hooks/useMatchedData';
import {sessionService} from '~/core/session.server';
import {View} from '~/ui/view';
import {cn} from '~/utils';

export const action = async ({request}: ActionFunctionArgs) => {
  if (request.method === 'POST') {
    const theme = await sessionService.theme.get(request.headers);
    const newTheme = theme?.theme === 'dark' ? 'light' : 'dark';
    const {headers} = await sessionService.theme.set(request.headers, {theme: newTheme});
    return json({}, {headers});
  }
  return {};
};

export const loader = async ({params, request}: LoaderFunctionArgs) => {
  const localeFromCookie = await sessionService.dictionaryLanguage.get(request.headers);
  const localeFromParamsValidated = dictionaryLanguageSchema.safeParse(params.lang);
  const defaultLocaleValidated = dictionaryLanguageSchema.safeParse(
    request.headers.get('Accept-Language')?.split(',')[0]?.split('-')[0],
  );

  if (localeFromParamsValidated.success) {
    const themeSession = await sessionService.theme.get(request.headers);
    const theme = 'light' || themeSession?.theme || 'light';

    const locale = localeFromParamsValidated.data;
    const t = getDictionary(locale);
    if (localeFromCookie?.locale !== locale) {
      const {headers} = await sessionService.dictionaryLanguage.set(request.headers, {locale});

      return json({t, locale, theme}, {headers});
    }

    return {t, locale, theme};
  }

  const locale = localeFromCookie?.locale || (defaultLocaleValidated.success && defaultLocaleValidated.data) || 'en';

  const urlWithoutHost = request.url.split('/').slice(3);

  throw redirect(`/${locale}/${urlWithoutHost.join('/')}`, {status: 301});
};

export default function LangPage() {
  const {theme} = useLoaderData<typeof loader>();
  return (
    <View>
      <div
        className={cn(
          theme === 'light' ? '' : 'dark',
          'min-h-screen flex flex-col transition-colors duration-500 ease-in-out',
          'bg-background',
        )}>
        <Outlet />
      </div>
    </View>
  );
}

export const useDictionary = () => {
  const {t, locale} = useMatchedData<typeof loader>('routes/$lang');
  const translator = createTranslator(t);
  return {t: translator, locale};
};

export const useTheme = () => {
  const {theme} = useMatchedData<typeof loader>('routes/$lang');
  return {theme};
};
